import { DialogBase, getBasicHooks, dialogInit } from "components/Pizze/Dialog/Base";
import { $, $all, forEach, on } from "@olmokit/dom";
import "./index.scss";

/**
 * Component: DialogPrize
 */

/** dialog id */
export const id = "dialog-pizze";

/**
 * Component: DialogProfile
 *
 */
function DialogPizze() {

  const dialog = DialogBase(".DialogPizze:", { id }, { ...getBasicHooks() });

  const buttons = $all('[data-dialog-pizze]');
  const prize = $all('.Pizze:texthidden');
  forEach(buttons, (button, index) => {
    on(button, 'click', () => {
      const image = $('.Pizze:texthidden_txt', prize[index]).cloneNode(true);
      const dialogImage = $('.DialogPizze:text');
      dialogImage.innerHTML = '';
      dialogImage.appendChild(image);
    })
  });

  return dialog;
}

/** dialog instance */
export const instance = DialogPizze();

/** dialog auto initialiser */
export function initDialogDescription() {
  dialogInit(instance);
}
