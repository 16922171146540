import "./index.scss";
import { initDialogPrize } from "components/Awards/Dialog/Prize";
import { $ } from "@olmokit/dom";
import gsap, { Linear } from "gsap";

/**
 * Component: Awards
 */
export function Awards() {
  console.log("Awards mounted.");
  initDialogPrize();

  const pizza = $('.Awards:illustrator');
  gsap.to(pizza, {duration: 20, rotation:"360", ease:Linear.easeNone, repeat:-1})
}
