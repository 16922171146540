import "./index.scss";
import { $, addClass } from "@olmokit/dom";
import gsap, { Linear } from "gsap";
import { device } from "@olmokit/core/detect";

/**
 * Component: Intro
 */
export function Intro() {
  console.log("Intro mounted.");

  const intro = $('.Intro:');
  const pizzatonda = $('.Intro:pizza-tonda svg');
  const pizzatrancio = $('.Intro:pizza-trancio svg');
  const pomodorini = $('.Intro:pizza-pomodorini svg');
  const basilico = $('.Intro:basilico svg');
  const peperone = $('.Intro:peperone svg');
  const pizzatrancio2 = $('.Intro:pizza-trancio-2 svg');
  const cipollahalf = $('.Intro:cipolla-half svg');

  gsap.to(pizzatonda, {duration: 20, rotation:"360", ease:Linear.easeNone, repeat:-1})

  setTimeout(() => {
    addClass(intro, 'intro');
  }, 1000);

  const desktop = !device().any;
  
  if(desktop){
    window.addEventListener("scroll", function() {
      const distance = window.scrollY
      // document.querySelector("header").style.transform = `translateY(${distance * 1}px)`
      if(distance < 800){
        pizzatrancio.style.transform = `translateY(${distance * 0.2}px)`;
        basilico.style.transform = `translateY(${distance * 0.05}px)`;      
        pizzatrancio2.style.transform = `translateY(${distance * 0.4}px)`;
        pomodorini.style.transform = `translateY(${distance * 0.2}px)`;
        peperone.style.transform = `translateY(${distance * 0.1}px)`;
        cipollahalf.style.transform = `translateY(${distance * 0.2}px)`;
      }
    });
  }


}
