import { DialogBase, getBasicHooks, dialogInit } from "components/Awards/Dialog/Base";
import { $, $all, forEach, on } from "@olmokit/dom";
import "./index.scss";

/**
 * Component: DialogPrize
 */

/** dialog id */
export const id = "dialog-prize";

/**
 * Component: DialogProfile
 *
 */
function DialogPrize() {

  const dialog = DialogBase(".DialogPrize:", { id }, { ...getBasicHooks() });

  const buttons = $all('[data-dialog-prize]');
  const prize = $all('.Awards:prize');
  forEach(buttons, (button, index) => {
    on(button, 'click', () => {
      const image = $('.Awards:prize_img', prize[index]).cloneNode(true);
      const dialogImage = $('.DialogPrize:image');
      dialogImage.innerHTML = '';
      dialogImage.appendChild(image);
    })
  });

  return dialog;
}

/** dialog instance */
export const instance = DialogPrize();

/** dialog auto initialiser */
export function initDialogPrize() {
  dialogInit(instance);
}
