import { DialogBase, getBasicHooks, dialogInit } from "components/Video/Dialog/Base";
import "./index.scss";

/**
 * Component: DialogVideo
 */

/** dialog id */
export const id = "dialog-video";

/**
 * Component: DialogProfile
 *
 */
function DialogVideo() {

  const dialog = DialogBase(".DialogVideo:", { id }, { ...getBasicHooks() });

  return dialog;
}

/** dialog instance */
export const instance = DialogVideo();

/** dialog auto initialiser */
export function initDialogVideo() {
  dialogInit(instance);
}
