/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";
import { FormContact } from  "components/FormContact";
import { Skills } from  "components/Skills";
import { Awards } from  "components/Awards";
import { Pizze } from  "components/Pizze";
import { Corsi } from  "components/Corsi";
import { Testimonial } from  "components/Testimonial";
import { Gallery } from  "components/Gallery";
import { Technics } from  "components/Technics";
import { Intro } from  "components/Intro";
import { Video } from  "components/Video";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { globalConf } from "@olmokit/core/data";
console.log('globalConf.cmsApiUrl', globalConf.cmsApiUrl);

console.log("Route home/index.js mounted.");

FormContact();
Video();
Skills();
Awards();
Pizze();
Corsi();
Testimonial();
Technics();
Intro();
Gallery();

const url = window.location.href;
const string = url.indexOf('#');

window.onscroll = function (e) {
    if(string >= 0){
        console.log('string', string);
        window.history.replaceState({}, "", `${location.pathname}`);
    }
} 

AOS.init();