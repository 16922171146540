import "./index.scss";
import { $ } from "@olmokit/dom";
import { autoConvertToLocalDates } from "@olmokit/core/helpers/date";
import gsap from "gsap";
import { device } from "@olmokit/core/detect";

/**
 * Component: Testimonial
 */
export function Testimonial() {
  console.log("Testimonial mounted.");

  autoConvertToLocalDates();

  const desktop = !device().any;

  function sliders() {

    let glide, anchors, breakpoints, controls, lazyLoad, swipe;

    import("@olmokit/core/glide").then(({ glide, Anchors, Breakpoints, Controls, LazyLoad, Swipe }) => {
      glide = glide;
      anchors = Anchors;
      breakpoints = Breakpoints;
      controls = Controls;
      lazyLoad = LazyLoad;
      swipe = Swipe;

      const glideOptions = {
        type: "carousel",
        rewind: false,
        perView: 3,
        startAt: 1,
        focusAt: 1,
        gap: 30,
        animationDuration: 300,
        breakpoints: {
          1024: {
            perView: 3
          },
          576: {
            startAt: 0,
            focusAt: 0,
            perView: 1
          }
        }
      };

      const slider = $('.Testimonial:slider');

      const sliderImages = glide(slider, glideOptions);

      sliderImages.on('build.after', function () {
        glideHandleHeight();
      });

      sliderImages.on('run.after', function () {
        glideHandleHeight();
      });

      sliderImages.mount({
        swipe,
        lazyLoad,
        anchors,
        controls,
        breakpoints
      });

      const $opacity = $('.Testimonial:');
      $opacity.style.opacity = 1;

      function glideHandleHeight() {
        const activeSlide = $('.Testimonial: .is-active');
        const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;        
        
        const glideTrack = $('.Testimonial: .glide__track');
        const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

        if (activeSlideHeight !== glideTrackHeight) {
          if(!desktop){
            gsap.to(glideTrack, {height: activeSlideHeight});
          }
          // console.log('activeSlideHeight', activeSlideHeight);
          // glideTrack.style.height = `${activeSlideHeight}px`;
        }
      }

    });
  }

  sliders();


  // const carousel = document.querySelector('.glide');

  // if (carousel) {
  //   const glideCarousel = new Glide('.glide');

  //   // Automated height on Carousel build
  //   glideCarousel.on('build.after', function () {
  //     glideHandleHeight();
  //   });

  //   // Automated height on Carousel change
  //   glideCarousel.on('run.after', function () {
  //     glideHandleHeight();
  //   });

  //   // Mount!
  //   glideCarousel.mount({
  //     type: 'carousel',
  //   });

  //   // Resize height
  //   function glideHandleHeight() {
  //     const activeSlide = document.querySelector('.glide__slide--active');
  //     const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;

  //     const glideTrack = document.querySelector('.glide__track');
  //     const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

  //     if (activeSlideHeight !== glideTrackHeight) {
  //       glideTrack.style.height = `${activeSlideHeight}px`;
  //     }
  //   }
  // }

}
