import "./index.scss";
import { initDialogVideo } from "components/Video/Dialog/Video";
import { $, on } from "@olmokit/dom";

/**
 * Component: Video
 */
export function Video() {
  console.log("Video mounted.");

  import("@olmokit/core/player").then(({ Player }) => {
    Player();
    initDialogVideo();

    const $playInner = $('.play_inner');
    const $vjsposter = $('.vjs-poster');
    on($playInner, 'click', () => {
      $vjsposter.click();
    });
    
    const $mobilePlay = $('.mobilePlay');
    const $playMobile = $('.play-mobile');
    on($playMobile, 'click', () => {
      $mobilePlay.play();
      console.log('play');
    });
  });

  
}
