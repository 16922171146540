import { I18nLinks } from "@olmokit/core/i18n/links";
import "components/Hamburger";
// import "utils/logo.scss";
import "./index.scss";
import {
  $,
  $all,
  addClass,
  removeClass,
  on,
  off,
  setVendorCSS,
  forEach
} from "@olmokit/dom";
import { parseSize } from "@olmokit/core/helpers/jss";

/**
 * Component: Header
 *
 */
export function Header() {
  // const header = BaseHeader();
  I18nLinks();

  const options = {
    styles: {
      expandTime: 300,
    }
  };
  const $root = $(".Header:");
  const expandTime = parseSize(options.styles.expandTime);
  const $toggle = $(".Header:toggle", $root);
  const $collapsable = $(".Header:collapse", $root);
  const $bg = $(".Header:bgExpanded", $root);

  /** @type {HeaderStatus} */
  let status = "collapsed";

  // localeSwitch();

  // default status as collapsed
  _setStatus("collapsed");

  // immediately clamp the collpsable header part, it won't never be open
  // at this point.
  // if ($bg) $bg.style.bottom = "100%";

  if ($collapsable) {
    setVendorCSS($collapsable, "transitionDuration", expandTime);
  }

  if ($toggle) {
    on($toggle, "click", handleToggle);
    on(document, "click", handleClickOutside);
  }

  const $items = $all('.Header:nav__item ');
  forEach($items, (item) => {
    on(item, 'click', () => {
      collapse();
    });
  });

  /**
   * Handle click outside
   *
   * @param {MouseEvent} event
   */
  function handleClickOutside(event) {
    if (status === "expanded") {
      // @ts-ignore
      if (!$root.contains(event.target)) {
        collapse();
      }
    }
  }

  /**
   * Handle toggle
   */
  function handleToggle() {
    if (status !== "expanded") {
      expand();
    } else {
      collapse();
    }
  }

  /**
   * Expand
   *
   */
  function expand() {

    addClass($collapsable, "is-in");
    // then we are expanded
    setTimeout(() => {
      _setStatus("expanded");
    }, expandTime * 1.5);

    addClass($toggle, "is-active");
  }

  /**
   * Collapse
   *
   */
  function collapse() {
    // first slide out the collapsable
    removeClass($collapsable, "is-in");

    // then we are collapsed
    setTimeout(() => {
      _setStatus("collapsed");
    }, expandTime * 1.5);

    removeClass($toggle, "is-active");
  }

  /**
   * Set status
   *
   * @param {HeaderStatus} newValue
   */
  function _setStatus(newValue) {
    status = newValue;
    $root.setAttribute("data-status", newValue);
  }

  /**
   * Destroy
   *
   */
  function destroy() {
    off($toggle, "click", handleToggle);
    off(document, "click", handleClickOutside);
  }

  return {
    /**
     * Set variant
     *
     * @param {"light" | "dark"} variant
     */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
      );
    },
  };
}