import "./index.scss";
import { $all, forEach } from "@olmokit/dom";
// import zacc from "@olmokit/core/zacc";

/**
 * Component: Corsi
 */
export function Corsi() {
  console.log("Corsi mounted.");

  import("@olmokit/core/zacc").then((module) => {

    const zacc = module.default;

    //Zacc
    //_____________________________________________________
    forEach($all(".Corsi:collapsable"), ($collapsable) => {
      zacc($collapsable, {
        duration: 300,
        // opened: () => {
        //   refreshSidebar();
        // },
        // closed: () => {
        //   refreshSidebar();
        // },
      });
    });

  });

}
