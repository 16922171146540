import "./index.scss";
import { $ } from "@olmokit/dom";
import { device } from "@olmokit/core/detect";
import gsap from 'gsap';

/**
 * Component: Gallery
 */
export function Gallery() {
  console.log("Gallery mounted.");

  const desktop = !device().any;

  function sliders() {

    let glide, anchors, breakpoints, controls, lazyLoad, swipe, autoplay;

    import("@olmokit/core/glide").then(({ glide, Anchors, Breakpoints, Controls, LazyLoad, Swipe, Autoplay }) => {
      glide = glide;
      anchors = Anchors;
      breakpoints = Breakpoints;
      controls = Controls;
      lazyLoad = LazyLoad;
      swipe = Swipe;
      autoplay = Autoplay;

      const glideOptions = {
        type: "carousel",
        rewind: false,
        perView: 3,
        startAt: 1,
        focusAt: 1,
        gap: 30,
        animationDuration: 300,
        breakpoints: {
          1024: {
            perView: 3
          },
          576: {
            startAt: 0,
            focusAt: 0,
            perView: 1
          }
        }
      };

      const slider = $('.Gallery:slider');

      const sliderImages = glide(slider, glideOptions);      

      sliderImages.on('build.after', function () {
        glideHandleHeight();
      });

      sliderImages.on('run.after', function () {
        glideHandleHeight();
      });      

      sliderImages.mount({
        swipe,
        lazyLoad,
        anchors,
        controls,
        breakpoints,
        autoplay
      });

      const $opacity = $('.Gallery:');
      $opacity.style.opacity = 1;      

      function glideHandleHeight() {
        const activeSlide = $('.Gallery: .is-active');
        const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;        
        
        const glideTrack = $('.Gallery: .glide__track');
        const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

        if (activeSlideHeight !== glideTrackHeight) {
          if(!desktop){
            gsap.to(glideTrack, {height: activeSlideHeight});
          }
          // console.log('activeSlideHeight', activeSlideHeight);
          // glideTrack.style.height = `${activeSlideHeight}px`;
        }
      }

    });
  }

  sliders();


}
