import "./index.scss";
import { $ } from "@olmokit/dom";
import gsap, { Linear } from "gsap";

/**
 * Component: Skills
 */
export function Skills() {
  console.log("Skills mounted.");

  const pizza = $('.Skills:pizza_inner');
  const pizzaOut = $('.Skills:pizza_outer');
  gsap.to(pizza, {duration: 20, rotation:"360", ease:Linear.easeNone, repeat:-1})
  gsap.to(pizzaOut, {duration: 20, rotation:"-360", ease:Linear.easeNone, repeat:-1})
}
